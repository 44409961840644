import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import stats1 from "../../../../../assets/sports_assests/stats.png";
import stats2 from "../../../../../assets/sports_assests/stats1.png";
import stats3 from "../../../../../assets/sports_assests/stats2.png";
import { getTellerDashboardStats } from "../../../../redux/dashboard_slice/dashboard_slice";
import Spinner from "../../../components/spinner";
import SlotsBarChart from "./slotsbar-chart";
import CourtsBarChart from "./courtbar-chart";

export default function DashboardTellerScreen() {
  const dispatch = useDispatch();

  const user = JSON.parse(localStorage.getItem("user"));

  const dashboardReducer = useSelector((state) => state.dashboard || {});
  const { loading, tellerDashboardStats } = dashboardReducer;

  useEffect(() => {
    if (user?.mobileNumber) {
      dispatch(getTellerDashboardStats(user?.mobileNumber));
    }
  }, [dispatch]);

  const tilesData = [
    {
      title: "Total Slots Booking",
      value: tellerDashboardStats?.totalSlotsBooked,
      backgroundImage: stats1,
      loading: loading,
    },
    {
      title: "Total Courts Booking",
      value: tellerDashboardStats?.totalCourtsBooked,
      backgroundImage: stats3,
      loading: loading,
    },
    {
      title: "Revenue Generated",
      value: (
        <>
          <span className="italic">PKR &nbsp;</span>
          {tellerDashboardStats?.revenueGenerated}
        </>
      ),
      backgroundImage: stats2,
      loading: loading,
    },
  ];

  return (
    <>
      <div className="stats-content">
        <div className="grid titles-grid">
          {tilesData.map((tile, index) => (
            <div className="md:col-12 lg:col-4 mobile-graphs" key={index}>
              <div
                className="tiles"
                style={{ backgroundImage: `url(${tile.backgroundImage})` }}
              >
                {tile.loading ? (
                  <Spinner />
                ) : (
                  <>
                    <h2>{tile.title}</h2>
                    <h1>{tile.value}</h1>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>

        <div className="grid">
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>Slots Management</h6>
                  <SlotsBarChart tellerDashboardStats={tellerDashboardStats} />
                </>
              )}
            </div>
          </div>
          <div className="md:col-12 lg:col-6 mobile-graphs">
            <div className="card charts-card">
              {loading ? (
                <Spinner />
              ) : (
                <>
                  <h6>Courts Management</h6>
                  <CourtsBarChart tellerDashboardStats={tellerDashboardStats} />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
