import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosClient from "../../services/axios_api"; 

const dashboardReducer = createSlice({
  name: "dashboard",
  initialState: {
    dashboardList: [],
    tellerDashboardStats: [],
    loading: false,
    error: null,
  },
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAdminDashboardStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.dashboardList = action.payload.data;
      })
      .addCase(getAdminDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTellerDashboardStats.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTellerDashboardStats.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tellerDashboardStats = action.payload.data;
      })
      .addCase(getTellerDashboardStats.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default dashboardReducer.reducer;
export const { resetChangeStatus } = dashboardReducer.actions;

// Thunks using axiosClient

export const getAdminDashboardStats = createAsyncThunk(
  "dashboard/stats",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`/AdminDashboard/AdminDashboardStats`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || error.message
      );
    }
  }
);

export const getTellerDashboardStats = createAsyncThunk(
  "tellerDashboardStats/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await axiosClient.get(`AdminDashboard/TellerDashboardStats/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg || error.message
      );
    }
  }
);
