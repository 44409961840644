import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/axios_api";

const initialState = {
  bookingHistoryList: [],
  loading: false,
  error: null,
};

const bookingHistoryReducer = createSlice({
  name: "bookingHistory",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getBookingHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.bookingHistoryList = action.payload;
      })
      .addCase(getBookingHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default bookingHistoryReducer.reducer;
export const { resetChangeStatus } = bookingHistoryReducer.actions;

export const getBookingHistory = createAsyncThunk(
  "bookingHistory/fetch",
  async ({ mobileNumber, startDate, endDate }, { rejectWithValue }) => {
    try {
      const requestData = {
        mobileNumber,
        startDate,
        endDate,
      };

      const response = await apiClient.post(
        `/PortalApp/GetBookingHistory`,
        requestData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
