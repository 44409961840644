import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiClient from "../services/axios_api";

const initialState = {
  portalList: [],
  loading: false,
  error: null,
  updateSuccess: null,
};

const portalReducer = createSlice({
  name: "portal",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.portalList = action.payload.data;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.responseCode === "00") {
          state.updateSuccess = action.payload;
          toast.success("Profile details updated successfully");
        } else {
          state.error = action.payload.responseDescription;
          toast.error(action.payload.responseDescription);
        }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.responseDescription || "An error occurred";
        toast.error(action.payload.responseDescription || "An error occurred");
      })
      .addCase(getSportsComplexes.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.portalList = action.payload.data.data;
      })
      .addCase(getSportsComplexes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default portalReducer.reducer;
export const { resetChangeStatus } = portalReducer.actions;

export const getMyProfile = createAsyncThunk(
  "portal/getMyProfile",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/PortalApp/GetMyProfile`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getSportsComplexes = createAsyncThunk(
  "portal/getSportsComplexes",
  async ({ pageNo = 1, pageSize = 10 }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/PortalApp/GetSportsComplexes`, {
        PageNo: pageNo,
        PageSize: pageSize,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "portal/updateProfile",
  async (portalData, { rejectWithValue }) => {
    try {
      const { token, ...data } = portalData;

      const response = await apiClient.put(`/PortalApp/UpdateMyProfile`, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
