import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiClient from "../../services/axios_api";

const initialState = {
  sportsComplexList: [],
  citiesList: [],
  complexWithCitiesList: null,
  sportComplexByIdList: [],
  loading: false,
  error: null,
};

const sportsComplexReducer = createSlice({
  name: "sportsComplex",
  initialState,
  reducers: {
    resetChangeStatus(state, action) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.addSuccess = undefined;
      state.deleteSuccess = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSportsComplexList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportsComplexList = action.payload.sports;
      })
      .addCase(getSportsComplexList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportComplexById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportComplexById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportComplexByIdList = action.payload.data;
      })
      .addCase(getSportComplexById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getCitiesList.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCitiesList.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.citiesList = action.payload.cities;
      })
      .addCase(getCitiesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(createSportsComplex.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createSportsComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
      })
      .addCase(createSportsComplex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error("Failed to add sport");
      })
      .addCase(getSportsComplexsTeller.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexsTeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        // console.log("API Response:", action.payload);
        if (action.payload.responseCode === "00") {
          state.complexWithCitiesList = action.payload.data;
          // toast.success(
          //   action.payload.responseDescription ||
          //     "Sports complexes fetched successfully!"
          // );
        } else {
          state.complexWithCitiesList = [];
          console.error("Error:", action.payload.responseDescription);
          toast.error(
            action.payload.responseDescription ||
              "An error occurred while fetching sports complexes."
          );
        }
      })
      .addCase(getSportsComplexsTeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        console.log(action);
      });
  },
});

export const getSportsComplexList = createAsyncThunk(
  "sportsComplex/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/PortalApp/GetAllSports`);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportsComplexsTeller = createAsyncThunk(
  "sportsComplexWithTeller/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`PortalApp/GetMySportsComplex`);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const createSportsComplex = createAsyncThunk(
  "sportsComplex/create",
  async (sportsComplexData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `/PortalApp/AddSportsComplex`,
        sportsComplexData
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export default sportsComplexReducer.reducer;
export const { resetChangeStatus } = sportsComplexReducer.actions;

export const getCitiesList = createAsyncThunk(
  "cities/fetch",
  async (_, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/PortalApp/GetAllCities`);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getSportComplexById = createAsyncThunk(
  "sportsById/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `/PortalApp/GetSportsComplexById/${id}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);
