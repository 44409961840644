import React from "react";
import { Image } from "primereact/image";

const CustomImagePreview = ({ src, alt, width }) => {
  const handleImageError = (e) => {
    e.target.onerror = null;
    e.target.src = "https://placehold.co/500x500?text=No+Image";
  };

  return (
    <Image
      src={src}
      alt={alt}
      width={width}
      onError={handleImageError}
      preview
    />
  );
};

export default CustomImagePreview;
