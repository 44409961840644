import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiClient from "../../services/axios_api";

const initialState = {
  adminList: [],
  sportComplexByIdList: [],
  loading: false,
  error: null,
  totalRecords: 0,
  currentPage: 1,
  pageSize: 10,
  updateSuccess: null,
};

const adminReducer = createSlice({
  name: "adminManagement",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getMyProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMyProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.adminList = action.payload.data;
      })
      .addCase(getMyProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.loading = false;

        if (action.payload.responseCode === "00") {
          state.updateSuccess = action.payload;
          toast.success("Profile details updated successfully");
        } else {
          state.error = action.payload.responseDescription;
          toast.error(action.payload.responseDescription);
        }
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.responseDescription || "An error occurred";
        toast.error(action.payload.responseDescription || "An error occurred");
      })
      .addCase(createAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.addSuccess = action.payload;
        toast.success(
          action.payload?.responseDescription || "Admin added successfully"
        );
      })
      .addCase(createAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(
          action.payload?.responseDescription || "An unexpected error occurred."
        );
      })
      .addCase(updateAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = action.payload;
        toast.success("Profile details updated successfully");
      })
      .addCase(updateAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAdminList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminList.fulfilled, (state, action) => {
        state.loading = false;
        state.adminList = action.payload.data;
        state.totalRecords = action.payload.recordsTotal;
      })
      .addCase(getAdminList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteSuccess = action.payload;
        state.adminList = state.adminList.filter(
          (admin) => admin.id !== action.payload
        );
        toast.success("Admin Inactive successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getAdminById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAdminById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.adminList = action.payload;
      })
      .addCase(getAdminById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSportComplexById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportComplexById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportComplexByIdList = action.payload.data;
      })
      .addCase(getSportComplexById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminReducer.reducer;
export const { resetChangeStatus } = adminReducer.actions;

export const getAdminList = createAsyncThunk(
  "adminManagement/getAdminList",
  async ({ pageNo, pageSize, searchText, isActive }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post("Admin/GetAdminsList", {
        pageNo,
        pageSize,
        searchText,
        isActive,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "adminManagement/deleteUser",
  async ({ adminId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(`/Admin/DeleteUser/${adminId}`);
      return adminId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getAdminById = createAsyncThunk(
  "adminManagement/getAdminListId",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/Admin/GetAdminById/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const createAdmin = createAsyncThunk(
  "adminManagement/createAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(`/Admin/CreateAdmin`, adminData);
      if (response.data?.responseCode === "00") {
        // Success response with code "00"
        return response.data;
      } else {
        // Any other response code is treated as an error
        return rejectWithValue(response.data);
      }
    } catch (error) {
      // Handle network or server errors
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "adminManagement/updateAdmin",
  async (adminData, { rejectWithValue }) => {
    try {
      const response = await apiClient.put(`/Admin/UpdateAdmin`, adminData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getSportComplexById = createAsyncThunk(
  "sportsById/fetch",
  async (id, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/Admin/GetSportsComplexById/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const getMyProfile = createAsyncThunk(
  "adminManagement/getMyProfile",
  async ({ token }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(`/Admin/GetMyProfile`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "adminManagement/updateProfile",
  async (portalData, { rejectWithValue }) => {
    try {
      const { token, ...data } = portalData;

      const response = await apiClient.put(`/Admin/UpdateMyProfile`, data);

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);
