import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import Paginator from "../../../components/paginator";
import useDebounce from "../../../components/hooks/useDebounce";
import Loader from "../../../components/loader";
import { confirmDialog } from "primereact/confirmdialog";
import deleteIcon from "../../../../../assets/sports_assests/delete.png";
import editIcon from "../../../../../assets/sports_assests/edit_icon.png";
import userIcon from "../../../../../assets/sports_assests/user-icon.png";
import { Tag } from "primereact/tag";
import CustomImagePreview from "../../../components/custom_imagepreview";
import {
  getTellersList,
  deleteUser,
} from "../../../../redux/user_slice/teller_slice";
import { formatDate, renderTextWithTooltip } from "../../../components/helper";
import { Dialog } from "primereact/dialog";
import EditAddForm from "./edit-add-form.js";

export default function TellerManagementScreen() {
  const [filteredList, setFilteredList] = useState([]);
  const dispatch = useDispatch();

  const tellerReducer = useSelector((state) => state.tellerManagement || {});
  const { tellerList, loading, totalRecords } = tellerReducer;

  const [globalFilter, setGlobalFilter] = useState("");
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [rows, setRows] = useState(10);
  const [filterSelectedStatus, setFilterSelectedStatus] = useState(null);
  const [expandedRows, setExpandedRows] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [showDialog, setShowDialog] = useState(false);

  const debouncedGlobalFilter = useDebounce(globalFilter, 500);

  useEffect(() => {
    dispatch(
      getTellersList({
        pageNo: page,
        pageSize: rows,
        isActive:
          filterSelectedStatus !== null
            ? filterSelectedStatus === "true"
            : null,
        searchText: debouncedGlobalFilter,
      })
    );
  }, [dispatch, page, rows, filterSelectedStatus, debouncedGlobalFilter]);

  const filterByStatus = (list, status) => {
    if (status === null) return list;
    return list.filter((item) => item.isActive.toString() === status);
  };

  useEffect(() => {
    if (Array.isArray(tellerList) && tellerList.length > 0) {
      const filtered = filterByStatus(tellerList, filterSelectedStatus);
      setFilteredList(filtered);
    } else {
      setFilteredList([]);
    }
  }, [tellerList, filterSelectedStatus]);

  const confirmDelete = (deleteId) => {
    confirmDialog({
      message: "Are you sure you want to inactivate this teller?",
      header: "User Status Confirmation",
      icon: "pi pi-info-circle",
      accept: async () => {
        try {
          const resultAction = await dispatch(
            deleteUser({ adminId: deleteId })
          );
          if (deleteUser.fulfilled.match(resultAction)) {
            dispatch(
              getTellersList({
                pageNo: page,
                pageSize: rows,
                isActive:
                  filterSelectedStatus !== null
                    ? filterSelectedStatus === "true"
                    : null,
                searchText: debouncedGlobalFilter,
              })
            );
          } else {
            console.error("Failed to delete teller:", resultAction.payload);
          }
        } catch (error) {
          console.error("Error during deletion:", error);
        }
      },
    });
  };

  const onHide = () => {
    setShowDialog(false);
  };

  const toggleDialogMode = (editMode = false) => {
    setShowDialog(true);
    setIsEdit(editMode);
  };

  const actionTemplate = (rowData) => {
    const handleDeleteClick = () => {
      confirmDelete(rowData.id);
    };

    return (
      <div className="flex">
        <Button
          className="p-button-rounded p-button-edit p-0 mr-3 action-images"
          onClick={() => {
            toggleDialogMode(true);
            setSelectedData(rowData);
          }}
          aria-label="Edit"
        >
          <img src={editIcon} alt="Edit" />
        </Button>
        <Button
          className="p-button-rounded p-button-delete action-images"
          onClick={handleDeleteClick}
          aria-label="Delete"
          disabled={!rowData.isActive}
        >
          <img src={deleteIcon} alt="Delete" />
        </Button>
      </div>
    );
  };

  const serialNumberTemplate = (rowData, column) => {
    return column.rowIndex + 1 + first;
  };

  const imageTemplate = (rowData) => {
    const imageUrl = rowData?.profileImagePath
      ? rowData?.profileImagePath
      : userIcon;

    return (
      <div>
        <CustomImagePreview src={imageUrl} />
      </div>
    );
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    setRows(event.rows);
  };

  const filterStatusValue = [
    { label: "Active", value: "true" },
    { label: "In-active", value: "false" },
  ];

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
  };
  const imageNestedTemplate = (rowData) => {
    return (
      <div>
        <CustomImagePreview src={rowData.image} />
      </div>
    );
  };

  const columnsData = [
    // {
    //   header: "Sr. No",
    //   body: serialNumberTemplate,
    // },
    {
      field: "name",
      header: "Name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      field: "userName",
      header: "Mobile Number",
    },
    {
      field: "createdDate",
      header: "Created Date",
      body: (rowData) => formatDate(rowData.createdDate),
    },
    {
      field: "email",
      header: "Email",
      body: (rowData) => renderTextWithTooltip(rowData, "email", "bottom"),
    },
    {
      field: "roles",
      header: "Role",
    },
    {
      header: "Status",
      body: (rowData) => (
        <Tag className={rowData.isActive ? "p-tag-success" : "p-tag-danger"}>
          {rowData.isActive ? "Active" : "In-active"}
        </Tag>
      ),
    },
    // {
    //   header: "Image",
    //   body: imageTemplate,
    // },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const nestedColumnsData = [
    {
      field: "complexName",
      header: "Complex Name",
    },
    {
      field: "cityName",
      header: "City",
      body: (rowData) => (rowData.cityName ? rowData.cityName : "N/A"),
    },

    {
      header: "Image",
      body: imageNestedTemplate,
    },
  ];
  const rowExpansionTemplate = (data) => {
    const sportsComplex = data.sportComplex || {};
    const complexData = [sportsComplex];

    return (
      <div className="p-3">
        <DataTable value={complexData}>
          {nestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={
          <div className="popup-header">
            <div className="popup-header-text">{isEdit ? "Edit" : "Add"}</div>
          </div>
        }
        visible={showDialog}
        onHide={onHide}
        className="dialog-size"
      >
        <EditAddForm
          onHide={onHide}
          formData={isEdit ? selectedData : null}
          currentPage={page}
          rowsPerPage={rows}
        />
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view"
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10  justify-content-end filter-responsive">
          <Dropdown
            className="status__dropdown dropdown-size mt-3"
            value={filterSelectedStatus}
            onChange={(e) =>
              setFilterSelectedStatus(e.value !== undefined ? e.value : null)
            }
            optionValue="value"
            options={filterStatusValue}
            optionLabel="label"
            placeholder="Select Status"
            showClear
          />

          <Button
            label="Add New Teller"
            icon="pi pi-plus"
            className="p-button ml-3"
            onClick={() => toggleDialogMode(false)}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          emptyMessage="No record found."
          value={filteredList}
          className="custom-data-table"
          dataKey="id"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>

        <Paginator
          first={first}
          rows={rows}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
        />
      </div>
    </>
  );
}
