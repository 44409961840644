import React, { useState, useEffect } from "react";
import SlotForm from "./slotForm";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { ErrorMessage } from "formik";

const CourtForm = ({ court, courtIndex, sportIndex, setFieldValue }) => {
  const [maxSlots, setMaxSlots] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [isImageEdit, setIsImageEdit] = useState(false);
  const [fileName, setFileName] = useState("");
  const [selectedDays, setSelectedDays] = useState(
    court.slots.daysAvailableArray || []
  );

  // useEffect(() => {
  //   setSelectedDays(court.slots.daysAvailableArray || []);
  // }, [court.slots.daysAvailableArray]);


  useEffect(() => {
    setSelectedDays(court.slots.daysAvailableArray || []);
  }, [court.slots.daysAvailableArray, court]);



  const days = [
    { name: "Monday", code: "1" },
    { name: "Tuesday", code: "2" },
    { name: "Wednesday", code: "3" },
    { name: "Thursday", code: "4" },
    { name: "Friday", code: "5" },
    { name: "Saturday", code: "6" },
    { name: "Sunday", code: "7" },
  ];

  const handleDayChange = (dayCode) => {
    const updatedDays = selectedDays.includes(dayCode)
      ? selectedDays.filter((day) => day !== dayCode)
      : [...selectedDays, dayCode];

    setSelectedDays(updatedDays);

    setFieldValue(
      `availableSports[${sportIndex}].courts[${courtIndex}].slots.daysAvailable`,
      updatedDays.join(",")
    );

    setFieldValue(
      `availableSports[${sportIndex}].courts[${courtIndex}].slots.daysAvailableArray`,
      updatedDays
    );
  };
  const handleFileUpload = (event) => {
    const fileInput = event.target;
    if (fileInput.files.length > 0) {
      const file = fileInput.files[0];

      // console.log(`Original File Size: ${(file.size / 1024).toFixed(2)} KB`);

      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          // console.log(`Original Dimensions: ${img.width}x${img.height}`);

          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Define target dimensions
          const TARGET_WIDTH = 640;
          const TARGET_HEIGHT = 480;

          // Maintain aspect ratio
          const aspectRatio = img.width / img.height;
          let newWidth, newHeight;

          if (img.width > img.height) {
            // Landscape image
            newWidth = TARGET_WIDTH;
            newHeight = Math.round(TARGET_WIDTH / aspectRatio);
          } else {
            // Portrait or square image
            newHeight = TARGET_HEIGHT;
            newWidth = Math.round(TARGET_HEIGHT * aspectRatio);
          }

          canvas.width = newWidth;
          canvas.height = newHeight;

          // Draw image on the canvas
          ctx.drawImage(img, 0, 0, newWidth, newHeight);

          // console.log(`Resized Dimensions: ${canvas.width}x${canvas.height}`);

          // Convert the resized image to a Blob and log its size
          canvas.toBlob(
            (blob) => {
              // console.log(
              //   `Resized Blob Size: ${(blob.size / 1024).toFixed(2)} KB`
              // );

              const resizedReader = new FileReader();
              resizedReader.onload = () => {
                let base64String = resizedReader.result;
                if (base64String.includes("base64,")) {
                  base64String = base64String.split("base64,")[1];
                }

                // console.log(
                //   `Resized Base64 Length: ${base64String.length} characters`
                // );

                // Update the form field with the resized base64 string
                setFileName(file.name);
                setIsImageEdit(true);
                setFieldValue(
                  `availableSports[${sportIndex}].courts[${courtIndex}].courtImage`,
                  base64String
                );
              };
              resizedReader.readAsDataURL(blob);
            },
            "image/jpeg",
            0.8 
          );
        };

        img.src = e.target.result;
      };

      reader.readAsDataURL(file);
    }
  };

  const showAdditionalAmountField =
    selectedDays.includes("6") || selectedDays.includes("7");

  const handleAdditionalChargesChange = (e) => {
    const newValue = e.target.value;

    court.slotsData.forEach((slot, ind) => {
      setFieldValue(
        `availableSports[${sportIndex}].courts[${courtIndex}].slotsData[${ind}].additionalCharges`,
        newValue
      );
    });
  };

  const getCommonAdditionalChargeValue = () => {
    const firstCharge = court.slotsData[0]?.additionalCharges;

    // Check if all slots have the same additionalCharges value
    const allSame = court.slotsData.every(
      (slot) => slot.additionalCharges === firstCharge
    );

    return allSame ? firstCharge : ""; // Return common value if all are the same, otherwise empty
  };

  useEffect(() => {
    const startTime = court.slots.startTime;

    const validStartTime = (() => {
      if (typeof startTime === "string") {
        const [hours, minutes, seconds] = startTime.split(":").map(Number);
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(seconds || 0);
        return date;
      }
      return new Date(startTime);
    })();

    const slotDuration = Number(court.slots.timePerSlot);
    const refreshTime = Number(court.slots.refreshTime) || 0;

    if (
      validStartTime instanceof Date &&
      !isNaN(validStartTime) &&
      slotDuration
    ) {
      const startHours = validStartTime.getHours();
      const startMinutes = validStartTime.getMinutes();
      const timeUntilMidnight = (24 - startHours) * 60 - startMinutes;
      const totalSlotTime = slotDuration + refreshTime;
      const calculatedMaxSlots = Math.floor(timeUntilMidnight / totalSlotTime);

      setMaxSlots(calculatedMaxSlots);
      setErrorMessage("");
    } else {
      setMaxSlots(null);
    }
  }, [
    court.slots.startTime,
    court.slots.timePerSlot,
    court.slots.refreshTime,
    courtIndex,
    setFieldValue,
  ]);

  const formatTimeToString = (date) => {
    if (!(date instanceof Date)) return "";

    let hours = date.getHours();
    const minutes = date.getMinutes();
    // const seconds = date.getSeconds();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;

    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")} ${ampm}`;
    return formattedTime;
  };

  const handleTotalSlotsChange = (e) => {
    const totalSlots = Number(e.target.value);

    const isStartTimeValid = Boolean(court.slots.startTime);
    const isTimePerSlotValid = Boolean(court.slots.timePerSlot);

    if (!isStartTimeValid || !isTimePerSlotValid) {
      setErrorMessage("You need to fill timing entries first.");
      return;
    }

    if (maxSlots !== null && totalSlots > maxSlots) {
      setErrorMessage(`Maximum ${maxSlots} slots can be added.`);
    } else {
      setErrorMessage("");

      const slots = [];
      let currentStartTime = timeStringToDate(court.slots.startTime);

      const refreshTime = Boolean(court.slots.refreshTime) || 0; // Default to 0 if not provided

      for (let i = 0; i < totalSlots; i++) {
        let slotEndTime = new Date(
          currentStartTime.getTime() + court.slots.timePerSlot * 60000
        );

        slots.push({
          slotName: `Slot ${i + 1}`,
          slotTime: `${formatTimeToString(
            currentStartTime
          )} - ${formatTimeToString(slotEndTime)}`,
          slotPrice: parseFloat(court.slots.amountPerSlot) || 0,
          isActive: false,
          additionalCharges: parseFloat(court.slotsData.additionalCharges) || 0,
        });

        currentStartTime = new Date(
          slotEndTime.getTime() + court.slots.refreshTime * 60000
        );
      }

      setFieldValue(
        `availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`,
        totalSlots
      );

      setFieldValue(
        `availableSports[${sportIndex}].courts[${courtIndex}].slotsData`,
        slots
      );
    }
  };

  function timeStringToDate(timeString) {
    if (typeof timeString !== "string") {
      return timeString;
    }

    const [hours, minutes, seconds] = timeString.split(":").map(Number);

    const date = new Date();

    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    date.setMilliseconds(0);

    return date;
  }

  return (
    <div>
      <div className="p-fluid formgrid grid">
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Court Name <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={50}
            // keyfilter={/^[a-zA-Z\s]*$/}
            placeholder="Court name"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].courtName`}
            value={court.courtName}
            onChange={(e) =>
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].courtName`,
                e.target.value
              )
            }
          />
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].courtName`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label
            htmlFor={`availableSports[${sportIndex}].courts[${courtIndex}].courtImage`}
          >
            Court Photo <span className="error-indicator required">*</span>
          </label>
          <div className="file-input-main-form">
            <input
              type="text"
              name="courtImage"
              value={
                court.courtImage && !isImageEdit
                  ? `${court.courtImage}`
                  : fileName || "No file chosen"
              }
              readOnly
              placeholder="No file chosen"
            />
            <label
              className="choose-file-button"
              htmlFor={`availableSports[${sportIndex}].courts[${courtIndex}].courtImage`}
            >
              Choose File
            </label>
            <input
              id={`availableSports[${sportIndex}].courts[${courtIndex}].courtImage`}
              name={`availableSports[${sportIndex}].courts[${courtIndex}].courtImage`}
              type="file"
              accept="image/*"
              className="choose-image-none"
              onChange={handleFileUpload}
            />
          </div>

          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].courtImage`}
            component="small"
            className="error-message"
          />
        </div>

        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Amount Per Slot
            <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={5}
            keyfilter={/^[0-9]*\.?[0-9]*$/}
            placeholder="Amount per slot"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.amountPerSlot`}
            value={court.slots.amountPerSlot}
            onChange={(e) =>
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.amountPerSlot`,
                e.target.value
              )
            }
          />
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.amountPerSlot`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Slot Timing Per Court (mins){" "}
            <span className="error-indicator required">*</span>
          </label>
          <InputText
            maxLength={4}
            keyfilter="pint"
            placeholder="Slot timing per court"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.timePerSlot`}
            value={court.slots.timePerSlot}
            onChange={(e) => {
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.timePerSlot`,
                e.target.value
              );
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.timePerSlot`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Starting Time Of Slot{" "}
            <span className="error-indicator required">*</span>
          </label>
          <Calendar
            timeOnly
            hourFormat="12"
            placeholder="Start time"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.startTime`}
            value={
              court.slots.startTime
                ? timeStringToDate(court?.slots?.startTime)
                : court.slots.startTime
            }
            onChange={(e) => {
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.startTime`,
                e.target.value
              );
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.startTime`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>Refresh Time (Optional) </label>
          <InputText
            maxLength={4}
            placeholder="Refresh time"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.refreshTime`}
            value={court.slots.refreshTime}
            onChange={(e) => {
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.refreshTime`,
                e.target.value
              );
              setFieldValue(
                `availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`,
                0
              );
            }}
          />
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.refreshTime`}
            component="small"
            className="error-message"
          />
        </div>
        <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
          <label>
            Total Slots <span className="error-indicator required">*</span>
          </label>
          <InputText
            placeholder="Total Slots"
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`}
            type="number"
            value={court.slots.totalSlots || ""}
            onChange={handleTotalSlotsChange}
          />
          {(maxSlots !== null || errorMessage) && (
            <small
              style={{
                display: "block",
                color: errorMessage ? "red" : "green",
              }}
            >
              {errorMessage ||
                `Maximum ${
                  maxSlots ? maxSlots : court.slots.totalSlots
                } slots can be added.`}
            </small>
          )}
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.totalSlots`}
            component="small"
            className="error-message"
          />
        </div>
        {court.slots.totalSlots > 0 && (
          <SlotForm
            slotsData={court.slotsData}
            setFieldValue={setFieldValue}
            courtIndex={courtIndex}
            sportIndex={sportIndex}
          />
        )}
        <div className="field md:col-6 lg:col-12 xl:col-12 responsive-col">
          <label>
            Available Days <span className="error-indicator required">*</span>
          </label>
          <div style={{ display: "flex", gap: "10px", flexWrap: "wrap" }}>
            {days.map((day) => (
              <div
                key={day.code}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  inputId={day.code}
                  value={day.code}
                  checked={selectedDays.includes(day.code)}
                  onChange={() => handleDayChange(day.code)}
                  className="checkbox-courts"
                />
                <label
                  htmlFor={day.code}
                  className="p-checkbox-label"
                  style={{ marginLeft: "10px", marginTop: "10px" }}
                >
                  {day.name}
                </label>
              </div>
            ))}
          </div>
          <ErrorMessage
            name={`availableSports[${sportIndex}].courts[${courtIndex}].slots.daysAvailable`}
            component="small"
            className="error-message"
          />
        </div>
        {showAdditionalAmountField && (
          <div className="field md:col-6 lg:col-6 xl:col-3 responsive-col">
            <label>Weekend Additional Charges (Optional)</label>
            <InputText
              keyfilter={/^[0-9]*\.?[0-9]*$/}
              maxLength={4}
              placeholder="Weekend Additional Charges"
              name={`availableSports[${sportIndex}].courts[${courtIndex}].slotsData.additionalCharges`}
              value={getCommonAdditionalChargeValue() || ""}
              onChange={handleAdditionalChargesChange}
            />
          </div>
        )}
      </div>
      {showAdditionalAmountField && (
        <p style={{ fontSize: "12px", color: "green" }}>
          Note: Amount + additional amount applies only on weekends.
        </p>
      )}
    </div>
  );
};

export default CourtForm;
