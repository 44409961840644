import React, { useEffect, useState } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { getBookingHistory } from "../../../redux/zsports_slice/booking_history_slice";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Tag } from "primereact/tag";
import Loader from "../../components/loader";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { Calendar } from "primereact/calendar";

export default function BookingHistoryScreen() {
  const bookingHistoryReducer = useSelector(
    (state) => state.bookingHistory || {}
  );
  const { bookingHistoryList, loading } = bookingHistoryReducer;
  const [filteredList, setFilteredList] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [expandedRows, setExpandedRows] = useState(null);
  const dispatch = useDispatch();
  const [qrData, setQrData] = useState("");

  const [isQrDialogVisible, setIsQrDialogVisible] = useState(false);
  const getStartOfMonth = () => moment().startOf("month").toDate();
  const getEndOfMonth = () => moment().endOf("month").toDate();

  const [dateRange, setDateRange] = useState([
    getStartOfMonth(),
    getEndOfMonth(),
  ]); // Default range to start of current month to end

  const user = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (user && user.mobileNumber) {
      const userMobileNumber = user.mobileNumber;
      const startDate =
        dateRange && dateRange[0]
          ? moment(dateRange[0]).format("YYYY-MM-DD")
          : moment(getStartOfMonth()).format("YYYY-MM-DD");
      const endDate =
        dateRange && dateRange[1]
          ? moment(dateRange[1]).format("YYYY-MM-DD")
          : moment(getEndOfMonth()).format("YYYY-MM-DD");

      const requestBody = {
        mobileNumber: userMobileNumber,
        // startDate: getStartOfYear(),
        // endDate: getEndOfYear(),
        startDate: startDate,
        endDate: endDate,
      };
      dispatch(
        getBookingHistory({
          ...requestBody,
        })
      );
    }
  }, [dispatch, dateRange]);

  useEffect(() => {
    if (bookingHistoryList && bookingHistoryList.zSportHistoryList) {
      const completedBookings = bookingHistoryList.zSportHistoryList.filter(
        (booking) => booking.status === "COMPLETED"
      );
      setFilteredList(completedBookings);
    }
  }, [bookingHistoryList]);

  const handleGlobalFilterChange = (e) => {
    const searchValue = e.target.value;
    setGlobalFilter(searchValue);
    const filteredData = bookingHistoryList.zSportHistoryList?.filter(
      (list) =>
        list.status === "COMPLETED" &&
        (list.location.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.court.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.sports.toLowerCase().includes(searchValue.toLowerCase()) ||
          list.mobileNumber.toLowerCase().includes(searchValue.toLowerCase()))
    );
    setFilteredList(filteredData);
  };

  const bookingDateTemplate = (rowData) => {
    return formatDate(rowData.bookingDate);
  };

  const scanDateTemplate = (rowData) => {
    if (rowData.scanDate === null) {
      return "N/A";
    }
    return formatDate(rowData.scanDate);
  };

  const formatDate = (value) => {
    return moment(value).format("MMMM Do YYYY");
  };

  const handleShowQrDetails = (qrString) => {
    setQrData(qrString);
    setIsQrDialogVisible(true);
  };

  const columnsData = [
    {
      field: "id",
      header: "Booking ID",
    },
    {
      field: "date",
      header: "Booking Date",
      body: bookingDateTemplate,
    },
    {
      field: "court",
      header: "Court Name",
    },
    {
      field: "location",
      header: "Complex Name",
    },
    {
      field: "mobileNumber",
      header: "Mobile Number",
    },
    {
      field: "sports",
      header: "Sports",
    },
    {
      field: "price",
      header: "Price (PKR)",
      // body: (rowData) => {
      //   return `${rowData.price.toString().toUpperCase()} `;
      // },
      body: (rowData) => {
        const slotPrice = rowData.timeSlots?.[0]?.slotPrice;
        return slotPrice ? `${slotPrice.toFixed(2)} PKR` : "N/A";
      },
    },
    {
      field: "scanDate",
      header: "Scan Date",
      body: scanDateTemplate,
    },
    {
      field: "status",
      header: "Status",
      body: (rowData) => {
        if (rowData.status === "COMPLETED") {
          return <Tag severity="success" value={rowData.status} />;
        }
        return null;
      },
    },
  ];

  const nestedColumnsData = [
    {
      field: "orderId",
      header: "Order ID",
    },
    {
      field: "slotName",
      header: "Slot Name",
    },
    {
      field: "slotPrice",
      header: "Slot Price (PKR)",
    },
    {
      field: "slotStatus",
      header: "Slot Status",
    },
    {
      field: "slotTime",
      header: "Slot Time",
    },
    {
      field: "status",
      header: "Status",
      body: (rowData) => {
        if (rowData.scanStatus) {
          return (
            <Tag
              severity={
                rowData.scanStatus === "Un-Scanned" ? "warning" : "success"
              }
              value={rowData.scanStatus}
            />
          );
        }
        return null;
      },
    },
    {
      field: "scanDetails",
      header: "Scan Details",
      body: (rowData) => (
        <Button
          className="qrbutton"
          label="View Details"
          icon="pi pi-qrcode"
          onClick={() => handleShowQrDetails(rowData.qrString)}
        />
      ),
    },
  ];

  const rowExpansionTemplate = (data) => {
    return (
      <div className="p-3">
        <DataTable value={data.timeSlots}>
          {nestedColumnsData.map((col, index) => (
            <Column
              key={index}
              field={col.field}
              header={col.header}
              body={col.body}
            />
          ))}
        </DataTable>
      </div>
    );
  };

  const renderQrDialogContent = (qrString) => {
    const qrDetails = qrString.split("\r\n").map((line) => {
      const [key, ...rest] = line.split(":");
      const value = rest.join(":").trim();
      return {
        key: key?.trim(),
        value: value || "Zindigi QR Details",
      };
    });

    return (
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f9f9f9",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <table style={{ borderCollapse: "collapse" }}>
          <tbody>
            {qrDetails.map((detail, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "1px solid #e0e0e0",
                  padding: "8px",
                  backgroundColor:
                    detail.key === "Zindigi" ? "#8fcfcc54" : "transparent",
                }}
              >
                <td
                  style={{ fontWeight: "bold", padding: "10px", color: "#555" }}
                >
                  {detail.key}
                </td>
                <td
                  style={{ padding: "10px", color: "#333", textAlign: "left" }}
                >
                  {detail.value}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  return (
    <>
      <Dialog
        header={
          <div
            className="popup-header header-icon"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "baseline",
            }}
          >
            <div className="popup-header-text">QR Details</div>
            <button
              onClick={() => setIsQrDialogVisible(false)}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                fontSize: "28px",
              }}
            >
              &times;
            </button>
          </div>
        }
        // style={{ width: "500px" }}
        visible={isQrDialogVisible}
        onHide={() => setIsQrDialogVisible(false)}
      >
        {qrData ? renderQrDialogContent(qrData) : "No details available"}
      </Dialog>
      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              className="mbl_view "
              placeholder="Search"
              value={globalFilter}
              onChange={handleGlobalFilterChange}
            />
          </span>
        </div>

        <div className="col-12 md:col-10 justify-content-end filter-responsive">
          <Calendar
            showIcon
            selectionMode="range"
            value={dateRange} // Bind the calendar to the dateRange state
            onChange={(e) => setDateRange(e.value)} // Update dateRange state on selection
            readOnlyInput
            hideOnRangeSelection
            // showButtonBar
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}
        <DataTable
          scrollable
          // scrollHeight="500px"
          emptyMessage="No record found."
          value={filteredList}
          paginator
          rows={10}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => {
            return (
              <Column
                key={index}
                field={data.field}
                header={data.header}
                body={data.body}
              />
            );
          })}
        </DataTable>
      </div>
    </>
  );
}
