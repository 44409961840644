
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import apiClient from "../../services/axios_api";

const initialState = {
  tellerList: [],
  complexListTeller: [],
  loading: false,
  error: null,
  totalRecords: 0,
  currentPage: 1,
  pageSize: 10,
  updateSuccess: null,
};

const tellerReducer = createSlice({
  name: "tellerManagement",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createTeller.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createTeller.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.addSuccess = action.payload;
        toast.success(
          action.payload?.responseDescription || "Teller created successfully"
        );
      })
      .addCase(createTeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getTellersList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTellersList.fulfilled, (state, action) => {
        state.loading = false;
        state.tellerList = action.payload.data;
        state.totalRecords = action.payload.recordsTotal;
      })
      .addCase(getTellersList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getCitiesWithSportsComplexesList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCitiesWithSportsComplexesList.fulfilled, (state, action) => {
        state.loading = false;
        state.complexListTeller = action.payload;
      })
      .addCase(getCitiesWithSportsComplexesList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(deleteUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.loading = false;
        state.deleteSuccess = action.payload;
        state.tellerList = state.tellerList.filter(
          (admin) => admin.id !== action.payload
        );
        toast.success("Teller Inactive successfully");
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(updateTeller.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTeller.fulfilled, (state, action) => {
        state.loading = false;
        state.updateSuccess = action.payload;
        toast.success(action?.payload?.responseDescription);
      })
      .addCase(updateTeller.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
        toast.error(action.payload);
      })
      .addCase(getTellersById.pending, (state, action) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTellersById.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tellerList = action.payload.data;
      })
      .addCase(getTellersById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tellerReducer.reducer;
export const { resetChangeStatus } = tellerReducer.actions;

export const getTellersList = createAsyncThunk(
  "tellerManagement/getTellersList",
  async (
    { token, pageNo, pageSize, searchText, isActive },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `/Admin/GetTellersList`,
        { pageNo, pageSize, searchText, isActive }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "tellerManagement/deleteUser",
  async ({ adminId }, { rejectWithValue }) => {
    try {
      const response = await apiClient.delete(
        `/Admin/DeleteUser/${adminId}`
      );
      return adminId;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

export const getTellersById = createAsyncThunk(
  "tellerManagement/getTellersById",
  async ({ id, token }, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `/Admin/GetTellersById/${id}`
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg ||
          error.message ||
          "An unexpected error occurred."
      );
    }
  }
);

export const createTeller = createAsyncThunk(
  "tellerManagement/createTeller",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;

      // Using axiosApi or apiClient (replace Axios with the one with interceptors)
      const response = await apiClient.post(
        `/Admin/createTeller`,
        data
      );

      const { responseCode, responseDescription } = response.data;

      // Handle error responses
      if (responseCode === "700") {
        return rejectWithValue({ responseCode, responseDescription });
      } else if (responseCode !== "200" && responseCode !== "00") {
        throw new Error(responseDescription || "Unexpected error occurred.");
      }

      // Return the success response
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data?.msg ||
          error.message ||
          "An unexpected error occurred."
      );
    }
  }
);

export const updateTeller = createAsyncThunk(
  "tellerManagement/updateTeller",
  async (adminData, { rejectWithValue }) => {
    try {
      const { token, ...data } = adminData;

      const response = await apiClient.put(
        `/Admin/updateTeller`,
        data
      );

      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || error.message || "An unexpected error occurred."
      );
    }
  }
);

export const getCitiesWithSportsComplexesList = createAsyncThunk(
  "tellerManagement/CitiesWithSportsComplexesList",
  async ({}, { rejectWithValue }) => {
    try {
      const response = await apiClient.get(
        `/Admin/CitiesWithSportsComplexesList`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response && error.response.data?.msg
          ? error.response.data.msg
          : error.message || "An unexpected error occurred."
      );
    }
  }
);
