import React, { useState } from "react";
import { Button } from "primereact/button";
import AdminManagementScreen from "./admin-management/admin-management";
import TellerManagementScreen from "./teller-management/teller-management";

export default function UserManagementScreen() {
  const [currentTab, setCurrentTab] = useState("Admin Management");

  const handleTabChange = (tab) => {
    setCurrentTab(tab);
  };

  return (
    <>
      <div className="mb-4 tab-buttons">
        <Button
          onClick={() => handleTabChange("Admin Management")}
          className={`p-button ${
            currentTab === "Admin Management"
              ? "p-button-text-bold active-tab"
              : ""
          }`}
        >
          Admin Management
        </Button>
        <Button
          onClick={() => handleTabChange("Teller Management")}
          className={`p-button ${
            currentTab === "Teller Management"
              ? "p-button-text-bold active-tab"
              : ""
          }`}
        >
          Teller Management
        </Button>
      </div>

      <div>
        {currentTab === "Admin Management" && <AdminManagementScreen />}
        {currentTab === "Teller Management" && <TellerManagementScreen />}
      </div>
    </>
  );
}
