import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import backIcon from "../../../../../assets/sports_assests/back.png";
import CustomImagePreview from "../../../components/custom_imagepreview";
import { Password } from "primereact/password";

const ViewDetailsRegister = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [rowData, setRowData] = useState(() => {
    const storedData = sessionStorage.getItem("viewDetailsData");
    return storedData
      ? JSON.parse(storedData)
      : location.state?.rowData || null;
  });

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("viewDetailsData");
    };
  }, []);

  const handleCancelClick = () => {
    navigate("/register-checker");
  };

  const [copyAddress, setCopyAddress] = useState("");
  const toast = React.useRef(null);

  const handleCopy = () => {
    if (rowData?.sportsComplexAddress) {
      navigator.clipboard
        .writeText(rowData.sportsComplexAddress)
        .then(() => {
          toast.current.show({
            severity: "success",
            summary: "Copied!",
            detail: "Address copied to clipboard.",
          });
        })
        .catch((err) => {
          toast.current.show({
            severity: "error",
            summary: "Error!",
            detail: "Failed to copy.",
          });
        });
    } else {
      toast.current.show({
        severity: "warn",
        summary: "No Address",
        detail: "No address available to copy.",
      });
    }
  };

  return (
    <>
      <div className="main-form">
        <div
          className="form-heading d-flex"
          style={{ alignItems: "center", gap: "10px" }}
        >
          <img
            src={backIcon}
            style={{ width: "30px", height: "30px", marginTop: "3px" }}
            onClick={handleCancelClick}
          />
          <h5>View Details</h5>
        </div>
        <div className="p-fluid formgrid grid">
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Name</label>
            <InputText value={rowData?.name || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Mobile Number</label>
            <InputText value={rowData?.mobileNumber || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Pin</label>
            <Password
              value={rowData?.pin || ""}
              feedback={false} 
              toggleMask 
              readOnly 
              inputId="pin"
              className="custom-password-input"
            />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="email">Email</label>
            <InputText
              placeholder="Enter email"
              value={rowData?.email || "N/A"}
              readOnly
            />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Status</label>
            <InputText value={rowData?.status || ""} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">City</label>
            <InputText value={rowData?.cityName || "N/A"} readOnly />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Complex Name</label>
            <InputText value={rowData?.complexName || ""} readOnly />
          </div>

          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label>Complex Address</label>
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputText
                name="sportsComplexAddress"
                value={rowData?.sportsComplexAddress || ""}
                onChange={(e) => setCopyAddress(e.target.value)}
                style={{ paddingRight: "2.5rem" }}
              />
              <i
                className="pi pi-clone"
                style={{
                  position: "absolute",
                  right: "0.5rem",
                  fontSize: "1.5rem",
                  cursor: "pointer",
                }}
                onClick={handleCopy}
              />
            </div>
            <Toast ref={toast} />
          </div>
          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="name">Remarks</label>
            <InputTextarea
              value={rowData?.remarks || "N/A"}
              rows={4.5}
              cols={30}
              readOnly
              style={{ height: "auto" }}
            />
          </div>

          <div className="field md:col-6 lg:col-3 xl:col-3 responsive-col">
            <label htmlFor="ProfileImageFile">Image</label>

            {rowData?.image && (
              <div className="preview-image">
                <CustomImagePreview src={rowData.image} alt="Profile" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDetailsRegister;
