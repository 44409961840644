import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  address: Yup.string().required("Address is required"),
  // address:Yup.string()
  //   .nullable()
  //   .matches(
  //     /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/,
  //     "Please enter a valid Google Maps link"
  //   )
  //   .required("Address is required"),
  // location: Yup.string()
  //   .nullable()
  //   .matches(
  //     /^https:\/\/maps\.app\.goo\.gl\/[A-Za-z0-9]+$/,
  //     "Please enter a valid Google Maps link"
  //   )
  //   .required("Location is required"),
  availableSports: Yup.array().of(
    Yup.object().shape({
      sportId: Yup.string().required("Sport name is required"),
      courts: Yup.array().of(
        Yup.object().shape({
          courtName: Yup.string().required("Court Name is required"),
          courtImage: Yup.string().required("Court Image is required"),
          slots: Yup.object().shape({
            timePerSlot: Yup.string().required("Time Per Slot is required"),
            daysAvailable: Yup.string().required("Available Day is required"),
            // refreshTime: Yup.string().required("Refresh Time is required"),
            amountPerSlot: Yup.string().required("Amount Per Slot is required"),
            startTime: Yup.string().required("Starting time is required"),
            totalSlots: Yup.number()
              .required("Total Slots are required")
              .positive("Total Slots must be positive")
              .integer("Total Slots must be an integer"),
          }),
          //   slotsData: Yup.array().of(
          //     Yup.object().shape({
          //       slotTime: Yup.string()
          //         .matches(
          //           /^\d{2}:\d{2}:\d{2} - \d{2}:\d{2}:\d{2}$/,
          //           "Slot Time must be in HH:mm:ss - HH:mm:ss format"
          //         )
          //         .required("Slot Time is required"),
          //       slotName: Yup.string().required("Slot Name is required"),
          //       slotPrice: Yup.number()
          //         .required("Slot Price is required")
          //         .positive("Slot Price must be positive"),
          //       additionalCharges: Yup.string(),
          //       isActive: Yup.boolean().required("Is Active is required"),
          //     })
          //   ),
        })
      ),
    })
  ),
});

export default validationSchema;
