import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import apiClient from "../../services/axios_api";

const initialState = {
  sportsComplexesList: [],
  totalRecords: 0,
  loading: false,
  error: null,
  approveSuccess: false,
  rejectSuccess: false,
};

export const getSportsComplexes = createAsyncThunk(
  "sportsComplexes/fetch",
  async (
    { pageNo, pageSize, searchText, isApproved, token },
    { rejectWithValue }
  ) => {
    try {
      const response = await apiClient.post(
        `/Admin/GetSportsComplexes`,
        {
          pageNo,
          pageSize,
          searchText,
          isApproved,
        },
      );

      if (
        response.data &&
        response.data.data &&
        Array.isArray(response.data.data.data)
      ) {
        return {
          sportsComplexesList: response.data.data.data,
          totalRecords: response.data.data.recordsTotal,
        };
      } else {
        throw new Error("Invalid response format");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const approveSportsComplex = createAsyncThunk(
  "sportsComplexes/approve",
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `/Admin/ApproveSportsComplex`,
        { id },
      );

      if (response.data.success) {
        return id;
      } else {
        throw new Error("Approval failed");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

export const rejectSportsComplex = createAsyncThunk(
  "sportsComplexes/reject",
  async ({ id, remarks, token }, { rejectWithValue }) => {
    try {
      const response = await apiClient.post(
        `/Admin/RejectSportsComplex`,
        { id, remarks },
      );

      if (response.data.success) {
        return { id, remarks };
      } else {
        throw new Error("Rejection failed");
      }
    } catch (error) {
      console.error("Error:", error);
      return rejectWithValue(
        error.response && error.response.data.msg
          ? error.response.data.msg
          : error.message
      );
    }
  }
);

const sportsComplexesReducer = createSlice({
  name: "sportsComplexes",
  initialState,
  reducers: {
    resetChangeStatus(state) {
      state.addSuccess = undefined;
      state.updateSuccess = undefined;
      state.deleteSuccess = undefined;
      state.approveSuccess = false;
      state.rejectSuccess = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSportsComplexes.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSportsComplexes.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.sportsComplexesList = action.payload.sportsComplexesList;
        state.totalRecords = action.payload.totalRecords;
      })
      .addCase(getSportsComplexes.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(approveSportsComplex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(approveSportsComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.approveSuccess = true;
        state.sportsComplexesList = state.sportsComplexesList.filter(
          (complex) => complex.id !== action.payload
        );
      })
      .addCase(approveSportsComplex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(rejectSportsComplex.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(rejectSportsComplex.fulfilled, (state, action) => {
        state.loading = false;
        state.rejectSuccess = true;
        state.sportsComplexesList = state.sportsComplexesList.filter(
          (complex) => complex.id !== action.payload
        );
      })
      .addCase(rejectSportsComplex.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default sportsComplexesReducer.reducer;
export const { resetChangeStatus } = sportsComplexesReducer.actions;
