import React from "react";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { InputMask } from "primereact/inputmask";
import { Checkbox } from "primereact/checkbox";
import { Password } from "primereact/password";

export default function CustomInputField({
  iden,
  formik,
  type = "text",
  className,
  label,
  placeHolder,
  options,
  optionLabel = "name",
  optionValue = "value",
  mask,
  onChange,
  value,
  feedback,
  onKeyUp,
  disabled = false,
  maxLength,
  icon,
  filter = false,
  keyfilter,
  required = false, 
}) {
  function titleCase(str) {
    return str.toLowerCase().replace(/\b\w/g, (s) => s.toUpperCase());
  }

  const isFormFieldValid = (name) =>
    !!(formik.touched[name] && formik.errors[name]);
  const getFormErrorMessage = (name) => {
    return (
      isFormFieldValid(name) && (
        <small className="p-error">{formik.errors[name]}</small>
      )
    );
  };

  const renderIcon = () => {
    if (icon) {
      return <i className={`pi ${icon}`}></i>;
    }
    return null;
  };

  const renderInputField = () => {
    if (type === "checkbox") {
      return (
        <div className="field-checkbox">
          <Checkbox
            name={iden}
            checked={formik.values[iden]}
            onChange={formik.handleChange}
          />
          <label>{label || titleCase(iden)}</label>
        </div>
      );
    } else if (type === "dropdown") {
      return (
        <Dropdown
          filter={filter}
          placeholder={placeHolder}
          id={iden}
          name={iden}
          options={options}
          optionLabel={optionLabel}
          optionValue={optionValue}
          value={formik.values[iden]}
          onChange={(e) => formik.setFieldValue(iden, e.value)}
          className={classNames("", {
            "p-invalid": isFormFieldValid(iden),
          })}
        />
      );
    } else if (type === "password") {
      return (
        <Password
          id={iden}
          name={iden}
          value={value || formik.values[iden]}
          onChange={onChange || formik.handleChange}
          type={type}
          maxLength={maxLength}
          onKeyUp={onKeyUp}
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid(iden) })}
          toggleMask
          feedback={feedback}
        />
      );
    } else if (type === "textArea" || type === "textarea") {
      return (
        <InputTextarea
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          type="text"
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid(iden) })}
        />
      );
    } else if (mask !== undefined) {
      return (
        <InputMask
          id={iden}
          mask={mask}
          name={iden}
          value={value || formik.values[iden]}
          onChange={onChange || formik.handleChange}
          type={type}
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid(iden) })}
        />
      );
    } else if (type === "number") {
      return (
        <InputText
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          type="number"
          placeholder={placeHolder}
          className={classNames({ "p-invalid": isFormFieldValid(iden) })}
        />
      );
    } else {
      return (
        <InputText
          keyfilter={keyfilter}
          disabled={disabled}
          autoComplete="off"
          autoSave="off"
          maxLength={maxLength}
          id={iden}
          name={iden}
          value={formik.values[iden]}
          onChange={formik.handleChange}
          onKeyUp={onKeyUp}
          type={type}
          placeholder={placeHolder}
          className={classNames("customInput", {
            "p-invalid": isFormFieldValid(iden),
          })}
        />
      );
    }
  };

  return (
    <div className={`field ${className}`}>
      {
  label && type !== "checkbox" && (
    <label htmlFor={iden}>
      {label ? titleCase(label) : titleCase(iden)}{" "}
      {required && <span className="staric-custom">*</span>}
    </label>
  )
}

      <div className="p-inputgroup">
        {icon && <span className="p-inputgroup-addon">{renderIcon()}</span>}
        {renderInputField()}
      </div>
      {onChange === undefined && getFormErrorMessage(iden)}
    </div>
  );
}
