import config from "../../config";
import { encryptionService } from "./encryption";

const axios = require("axios");

const axiosApi = axios.create();
const apiClient = axios.create({
  baseURL: `${config.baseUrl}/api`,
  headers: {
    "Content-Type": "application/json",
  },
});

// Refresh Token Function
// const refreshAccessToken = async () => {
//   const refreshToken = localStorage.getItem("refreshToken");

//   if (!refreshToken) {
//     console.error("No refresh token found.");
//     handleLogout();
//     return "";
//   }

//   try {
//     // Send refreshToken in the request body
//     const { data } = await axios({
//       method: "GET",
//       url: `${config.baseUrl}/api/Account/refresh/portal`,
//       data: { refreshToken }, // Send payload in the body
//     });

//     const newAccessToken = data?.accessToken;

//     if (newAccessToken) {
//       localStorage.setItem("accessToken", newAccessToken);
//       return newAccessToken;
//     } else {
//       throw new Error("Invalid token response");
//     }
//   } catch (error) {
//     console.error("Error refreshing access token:", error);
//     handleLogout();
//     return "";
//   }
// };



const handleLogout = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("refreshToken");
  window.location.href = "/#/login";
};

// Request Interceptor for API Calls
const setupInterceptors = (instance) => {
  instance.interceptors.request.use(
    async (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      if (config.data && instance === apiClient) {
        const encryptedText = await encryptionService.encryptAES(JSON.stringify(config.data));
        config.data = { Text: encryptedText };
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(
    async (response) => {
      if (instance === apiClient && response.data?.Text) {
        const decryptedText = await encryptionService.decryptAES(response.data.Text);
        response.data = JSON.parse(decryptedText);
      }
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        // const newToken = await refreshAccessToken();

        // if (newToken) {
        //   originalRequest.headers.Authorization = `Bearer ${newToken}`;
        //   return instance(originalRequest);
        // }
      }

      if (error.response?.data?.Text && instance === apiClient) {
        try {
          const decryptedErrorText = await encryptionService.decryptAES(error.response.data.Text);
          error.response.data = JSON.parse(decryptedErrorText);
        } catch (decryptionError) {
          console.error("Error decrypting the error response:", decryptionError);
        }
      }

      if (error.response?.status === 401) {
        handleLogout();
      }

      console.error("API error occurred:", error);
      return Promise.reject(error);
    }
  );
};

// Apply Interceptors
setupInterceptors(axiosApi);
setupInterceptors(apiClient);

export { axiosApi };
export default apiClient;
